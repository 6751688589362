import * as React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

// markup
const NotFoundPage = () => {
  return (
    <main className="page-container">
      <Helmet>
        <title>Muscarian Games - Not found</title>
      </Helmet>
      <h1 className="page-heading">Page not found</h1>
      <p className="page-copy-container">
        Sorry{" "}
        <span role="img" aria-label="Pensive emoji">
          😔
        </span>{" "}
        we couldn’t find what you were looking for.
        <br />
        {process.env.NODE_ENV === "development" ? (
          <>
            <br />
            Try creating a page in <code>src/pages/</code>.
            <br />
          </>
        ) : null}
        <br />
        <Link className="link" to="/">Go home</Link>.
      </p>
    </main>
  )
}

export default NotFoundPage
